<template>
 <div class="inner-section mt-3">
    <bread-cumb />
       <body-card>
       <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('elearning_tpm.training_material_info') }} {{ $route.params.id ? $t('globalTrans.modify') : $t('globalTrans.entry') }}</h4>
      </template>
          <b-container fluid>
              <b-row>
              <b-overlay :show="loading">
                <b-col sm="12" class="mt-3">
                  <slot>
                      <!-- =================Main application===================== -->
                    <div >
                      <ValidationObserver ref="prepare_schedule_form" v-slot="{ handleSubmit, reset }">
                        <!-- <b-overlay :show="loading"> -->
                          <b-form @submit.prevent="handleSubmit(cloneAddItem)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
                            <b-row>
                              <b-col xs="12" sm="12" md="4">
                                <ValidationProvider name="Fiscal Year Id" vid="fiscal_year_id" rules="required|min_value:1">
                                  <b-form-group
                                    label-for="fiscal_year_id"
                                    slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                    {{$t('globalTrans.fiscal_year')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                    plain
                                    v-model="data.fiscal_year_id"
                                    :options="fiscalYearList"
                                    id="clone_info_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col xs="12" sm="12" md="4">
                                <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required|min:2">
                                <b-form-group
                                    label-for="circular_memo_no"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                                  </template>
                                    <b-form-select
                                    plain
                                    v-model="data.circular_memo_no"
                                    :options="circularList"
                                    id="circular_memo_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                              </ValidationProvider>
                              </b-col>
                              <b-col xs="12" sm="12" md="4">
                                <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                                <b-form-group
                                    label-for="org_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="data.org_id"
                                      :options="orgList"
                                      id="org_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      disabled
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                              </b-col>
                              <b-col xs="12" sm="12" md="4">
                                <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                                <b-form-group
                                  label-for="training_title_id"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                    plain
                                    v-model="data.training_title_id"
                                    :options="trainingTitleList"
                                    id="training_title_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    disabled
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                              </b-col>
                              <b-col xs="12" sm="12" md="4">
                                <ValidationProvider name="Batch Id"  vid="batch_id" rules="required|min_value:1">
                                <b-form-group
                                  label-for="batch_id"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.batch')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                    plain
                                    v-model="data.batch_id"
                                    :options="batchList"
                                    id="batch_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                               </ValidationProvider>
                              </b-col>
                            </b-row>
                          </b-form>
                        <!-- </b-overlay> -->
                      </ValidationObserver>
                    </div>
                    <!-- =================Add More Start===================== -->
                    <body-card>
                      <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('elearning_tpm.training_material_info') }}</h4>
                      </template>
                      <template v-slot:body>
                        <b-row>
                          <ValidationObserver ref="details_item" v-slot="{ handleDetailsItem, reset }">
                            <b-form @submit.prevent="handleDetailsItem(saveUpdate)" @reset.prevent="reset">
                            <b-row class="m-2">
                              <b-col xs="12" sm="12" md="4" class="pl-0">
                                <ValidationProvider name="Material Name (En)" vid="material_name" :rules="data.details && data.details.length > 0 ? '' : 'required'">
                                <b-form-group
                                    label-for="material_name"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_tpm.material_name')}} {{$t('globalTrans.en')}}<span class="text-danger">*</span>
                                  </template>
                                    <b-form-input
                                    plain
                                    v-model="details.material_name"
                                    id="material_name"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                </b-form-input>
                                </b-form-group>
                              </ValidationProvider>
                              </b-col>
                              <b-col xs="12" sm="12" md="4" class="pl-0">
                                <ValidationProvider name="Material Name (Bn)" vid="material_name_bn" :rules="data.details && data.details.length > 0 ? '' : 'required'">
                                  <b-form-group
                                      label-for="material_name_bn"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('elearning_tpm.material_name')}} {{$t('globalTrans.bn')}}<span class="text-danger">*</span>
                                    </template>
                                      <b-form-input
                                      plain
                                      v-model="details.material_name_bn"
                                      id="material_name_bn"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                  </b-form-input>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col xs="12" sm="12" md="4" class="pl-0">
                                <ValidationProvider name="Unit Price" vid="unit_price" :rules="data.details && data.details.length > 0 ? '' : 'required'">
                                  <b-form-group
                                      label-for="unit_price"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('elearning_tpm.unit_price')}}<span class="text-danger">*</span>
                                    </template>
                                      <b-form-input
                                      plain
                                      v-model="details.unit_price"
                                      id="unit_price"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                      @keyup="totalAmount"
                                      min="0"
                                    >
                                  </b-form-input>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col xs="12" sm="12" md="4" class="pl-0">
                                <ValidationProvider name="Quantity" vid="quantity" :rules="data.details && data.details.length > 0 ? '' : 'required'">
                                  <b-form-group
                                      label-for="quantity"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('globalTrans.quantity')}}<span class="text-danger">*</span>
                                    </template>
                                      <b-form-input
                                      plain
                                      v-model="details.quantity"
                                      id="quantity"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                      @keyup="totalAmount"
                                      min="0"
                                    >
                                  </b-form-input>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col xs="12" sm="12" md="4" class="pl-0">
                                <ValidationProvider name="Total Price" vid="total_price" :rules="data.details && data.details.length > 0 ? '' : 'required'">
                                  <b-form-group
                                      label-for="total_price"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('globalTrans.total')}} {{$t('globalTrans.price')}}<span class="text-danger">*</span>
                                    </template>
                                      <b-form-input
                                      disabled
                                      plain
                                      v-model="details.total_price"
                                      id="total_price"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                  </b-form-input>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                            </b-form>
                          </ValidationObserver>
                        </b-row>
                      </template>
                    </body-card>
                    <br/>
                    <div class="d-flex justify-content-end">
                      <b-button class="btn-sm mb-2" type="button" @click="addMoreDetailsInfo" variant="success">
                        <span v-if="loading">
                            <b-spinner small label="Loading..."></b-spinner>
                        </span>
                        <span v-else>
                            <i class="fas fa-plus-circle m-0"></i>
                        </span>
                        {{data.details && data.details.length > 0 ? $t('elearning_tpm.add_more_material') : $t('elearning_tpm.add_material')}}
                    </b-button>
                    </div>
                    <!-- schedule details -->
                      <div class="table-wrapper table-responsive">
                      <ValidationObserver ref="prepare_schedule_form_details">
                        <table class="table table-striped table-hover table-bordered">
                                <thead>
                                    <tr class="bg-primary">
                                        <th scope="col" style="width: 7%"><div>{{ $t('globalTrans.sl_no') }}</div></th>
                                        <th scope="col" style="width: 20%" class="text-center"><div>{{ $t('elearning_tpm.material_name') }}</div></th>
                                        <th scope="col" style="width: 10%" class="text-center"><div>{{ $t('elearning_tpm.unit_price') }}</div></th>
                                        <th scope="col" style="width: 10%" class="text-center"><div>{{ $t('globalTrans.quantity') }}</div></th>
                                        <th scope="col" style="width: 10%" class="text-center"><div>{{ $t('globalTrans.total') }} {{ $t('globalTrans.price') }}</div></th>
                                        <th style="width: 10%"><div>{{ $t('globalTrans.action') }}</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="data.details && data.details.length > 0">
                                        <tr v-for="(item, index) in data.details" :key="index">
                                            <td  class="text-center">{{ $n(index+1) }}</td>
                                            <td class="text-center">{{ currentLocale === 'bn' ? item.material_name_bn : item.material_name }}</td>
                                            <td class="text-center"><b-form-input
                                                        id="unit_price"
                                                        v-model.number="item.unit_price"
                                                        @keyup="totalAmountTable(index)"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input></td>
                                            <td class="text-center"><b-form-input
                                                        id="quantity"
                                                        v-model.number="item.quantity"
                                                        @keyup="totalAmountTable(index)"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      ></b-form-input></td>
                                            <td class="text-center">{{ $n(item.total_price) }}</td>
                                            <td class="text-center">
                                              <b-button variant="action-btn delete mr-1" size="sm" :title="$t('globalTrans.delete')" @click="addMoreDetailsInfoRemove(index)" class="action-btn edit"><i class="ri-close-fill"></i></b-button>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                      <tr>
                                        <td colspan="13" align="center">{{ $t('globalTrans.noDataFound') }}</td>
                                      </tr>
                                    </template>
                                    <b-tr>
                                        <b-td colspan="4" class="text-right"><strong>{{ $t('globalTrans.grand_total') }}</strong></b-td>
                                        <b-td><strong>{{ $n(getGrantTotal()) }}</strong></b-td>
                                        <b-td colspan="6"></b-td>
                                      </b-tr>
                                </tbody>
                        </table>
                      </ValidationObserver>
                      </div>
                    <!-- =================Add More End===================== -->
                    <br>
                    <b-row>
                      <b-col xs="12" sm="12" md="4">
                      <ValidationProvider name="Note (En)" vid="note_en" :rules="{required: false}">
                          <b-form-group
                          slot-scope="{ valid, errors }"
                          label-for="note_en">
                          <template v-slot:label>
                              {{ $t('globalTrans.note') }} {{ $t('globalTrans.en') }}
                          </template>
                          <b-form-textarea
                              id="note_en"
                              v-model="data.note_en"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >{{ data.note_en }}</b-form-textarea>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                          </b-form-group>
                      </ValidationProvider>
                      </b-col>
                      <b-col xs="12" sm="12" md="4">
                      <ValidationProvider name="Note (Bn)" vid="note_bn" :rules="{required: false}">
                          <b-form-group
                          slot-scope="{ valid, errors }"
                          label-for="note_bn">
                          <template v-slot:label>
                              {{ $t('globalTrans.note') }} {{ $t('globalTrans.bn') }}
                          </template>
                          <b-form-textarea
                              id="note_bn"
                              v-model="data.note_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >{{ data.note_bn }}</b-form-textarea>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                          </b-form-group>
                      </ValidationProvider>
                      </b-col>
                    </b-row>
                </slot>
                <b-row class="text-right mb-3">
                  <b-col>
                    <b-button type="button" @click="saveUpdate()" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.save') }}</b-button>
                    <b-button variant="danger" class="btn-sm" @click="back">{{ $t('globalTrans.cancel') }}</b-button>
                  </b-col>
                </b-row>
                </b-col>
                </b-overlay>
              </b-row>
          </b-container>
     </body-card>
  </div>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { trainingMaterialInfoStore, trainingMaterialInfoUpdate, circularApproveList, trainingModuleWiseTrainer } from '../../api/routes'
import BreadCumb from '../../../../../components/BreadCumb.vue'
export default {
  name: 'Form',
  props: ['id'],
  components: { BreadCumb },
  data () {
    return {
      valid: null,
      saveBtnName: this.$route.params.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      circularLoading: false,
      data: {
        fiscal_year_id: this.$store.state.currentFiscalYearId,
        circular_memo_no: 0,
        org_id: 0,
        training_title_id: 0,
        batch_id: 0,
        grant_total_price: 0,
        note_en: '',
        note_bn: '',
        status: 0,
        details: []
      },
      garden_infos: {},
      assign_users: {},
      userList: [],
      batchList: [],
      circularList: [],
      trainingTitleList: [],
      circularMemoList: [],
      details: {
        material_name: '',
        material_name_bn: '',
        unit_price: 0,
        quantity: 0,
        total_price: 0
      }
    }
  },
  created () {
    this.getUserList()
    if (this.$route.params.id) {
      const tmp = this.getEditEntry()
      this.data = tmp
    }
    this.getCircularList()
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    currentLocale () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    gardenList () {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
          this.localizeUserList(newVal)
      }
    },
    'data.fiscal_year_id': function (newValue) {
      this.getCircularList(newValue)
    },
    'data.circular_memo_no': function (newValue) {
      this.getCircularMemoNo(newValue)
    },
    'data.org_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
      this.batchList = this.getBatchList(newValue)
    }
  },
  methods: {
    back () {
      this.$router.push('/training-e-learning-service/tpm/training-material-info')
    },
    // ================Garden Info Add=================
    addMoreGardenInfo () {
      if (this.garden_infos.garden_id) {
        const exist = this.details.garden_infos.find(gardenItem => gardenItem.garden_id === this.garden_infos.garden_id)
        if (!exist) {
          this.details.garden_infos.push(this.garden_infos)
          this.garden_infos = {
            garden_id: ''
          }
        } else {
          this.$toast.success({
          title: 'Warning',
          message: this.$t('globalTrans.data_exists'),
          color: '#E73813 '
        })
        }
      }
    },
    totalAmount () {
      if (this.details.unit_price && this.details.quantity) {
        const totalPrice = parseFloat(this.details.unit_price) * parseFloat(this.details.quantity)
        this.details.total_price = totalPrice.toFixed()
        return totalPrice
      }
      this.details.total_price = 0
    },
    totalAmountTable (row) {
      if (this.data.details[row].unit_price && this.data.details[row].quantity) {
        const totalPrice = parseFloat(this.data.details[row].unit_price) * parseFloat(this.data.details[row].quantity)
        this.data.details[row].total_price = totalPrice.toFixed()
        return totalPrice
      } else {
        this.data.details[row].total_price = 0
      }
    },
    addMoreGardenInfoRemove (index) {
      this.details.garden_infos.splice(index, 1)
    },
    // ================User Assing Info Add=================
    addMoreAssignUserInfo () {
      if (this.assign_users.user_id) {
        const exist = this.details.assign_users.find(userItem => userItem.user_id === this.assign_users.user_id)
        if (!exist) {
          this.details.assign_users.push(this.assign_users)
          this.assign_users = {
            user_id: ''
          }
        } else {
          this.$toast.success({
          title: 'Warning',
          message: this.$t('globalTrans.data_exists'),
          color: '#E73813 '
        })
        }
      }
    },
    addMoreAssignUserInfoRemove (index) {
      this.details.assign_users.splice(index, 1)
    },
    addMoreDetailsInfo () {
      if (this.details.material_name && this.details.material_name_bn && this.details.quantity && this.details.unit_price && this.details.total_price) {
        const exist = this.data.details.find(detailItem => detailItem.material_name === this.details.material_name)
        const exist2 = this.data.details.find(detailItem => detailItem.material_name_bn === this.details.material_name_bn)
        if (!exist && !exist2) {
          this.data.details.push(this.details)
          this.details = {
            material_name: '',
            material_name_bn: '',
            unit_price: 0,
            quantity: 0,
            total_price: 0
          }
        } else {
          this.$toast.success({
          title: 'Warning',
          message: this.$t('globalTrans.data_exists'),
          color: '#E73813 '
        })
        }
      } else {
        this.$toast.success({
          title: 'Warning',
          message: this.$t('globalTrans.invalidEntry'),
          color: '#E73813 '
        })
      }
    },
    addMoreDetailsInfoRemove (index) {
      this.data.details.splice(index, 1)
    },
    async saveUpdate (status = 1) {
    const isValid = await this.$refs.prepare_schedule_form.validate()
    const schedueDetailsValid = await this.$refs.prepare_schedule_form_details.validate()
    if (this.data.details.length === 0) {
        this.$toast.success({
          title: 'Warning',
          message: this.$t('elearning_tpm.data_empty'),
          color: '#E73813 '
        })
    }
      this.data.status = status
      if (isValid && schedueDetailsValid && this.data.details.length > 0) {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.$route.params.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${trainingMaterialInfoUpdate}/${this.$route.params.id}`, this.data)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, trainingMaterialInfoStore, this.data)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.$route.params.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$router.push('/training-e-learning-service/tpm/training-material-info')
      } else {
        // this.$refs.mainForm.setErrors(result.errors)
        this.$refs.prepare_schedule_form.setErrors(result.errors)
      }
    }
    },
    getGardenName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === id)
      if (obj) {
        return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getBatchList (orgId) {
      const batchList = this.$store.state.TrainingElearning.commonObj.batchList.filter(item => item.status === 1)
      if (orgId) {
        return batchList.filter(item => item.org_id === orgId)
      }
      return batchList
    },
    getCircularMemoList (orgId) {
      const circularMemoList = this.$store.state.TrainingElearning.commonObj.circularMemoList.filter(item => item.status === 1)
      if (orgId) {
        return circularMemoList.filter(item => item.org_id === orgId)
      }
      return circularMemoList
    },
    async getCircularList () {
      this.circularLoading = true
      const serchData = {
        fiscal_year_id: this.data.fiscal_year_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularApproveList + '/' + 'iab_circular_publications', serchData)
      if (!result.success) {
      } else {
        const listObject = result.data
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          }
        })
        this.circularList = tmpList
      }
      this.circularLoading = false
    },
    async getCircularMemoNo (getCircularMemoNo) {
      this.circularLoading = true
      if (getCircularMemoNo) {
        this.load = true
        const params = {
          circular_memo_no: getCircularMemoNo,
          table: 'iab_circular_publications'
        }
        this.trainerListData = []
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainingModuleWiseTrainer, params)
        if (!result.success) {
          this.data.org_id = 0
          this.data.training_title_id = 0
        } else {
          this.lcErrorMsg = ''
          const trData = result.data
          this.data.org_id = trData.org_id
          this.data.training_title_id = trData.training_title_id
          const tmpList = result.aryRange.map((obj, index) => {
            return { value: obj, text: obj }
          })
          this.selectDate = tmpList
          // this.trainerListData = result.trainerList
        }
        this.load = false
        this.circularLoading = false
      }
    },
    getTrainingTitleList (orgId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (orgId) {
        return trainingTitleList.filter(item => item.org_id === orgId)
      }
      return trainingTitleList
    },
    getGrantTotal () {
      let grantAmount = 0
      this.data.details.forEach(item => {
        grantAmount += parseFloat(item.total_price)
        this.data.grant_total_price = grantAmount
      })
      return grantAmount
    },
    async getUserList () {
      const btriOfficeId = this.$store.state.CommonService.commonObj.officeList.find(item => item.text_en.toLowerCase() === 'project development unit (pdu)').value
      this.userLoading = true
      const params = {
        office_id: btriOfficeId,
        org_id: 5
      }
      await RestApi.getData(authServiceBaseUrl, '/user-management/user/all-user-by-org', params).then(response => {
        const userList = response.map((obj, index) => {
            return Object.assign({}, obj, {
              text: this.currentLocale === 'en' ? obj.name : obj.name_bn, value: obj.user_id
            })
          })
          this.userList = userList.filter(item => item !== undefined)
      })
    },
    localizeUserList (locale) {
      const tmp = this.userList.map(item => {
        return Object.assign({}, item, {
          text: locale === 'en' ? item.name : item.name_bn
        })
    })
      this.userList = tmp
    },
    getEditEntry () {
      const itemId = this.$route.params.id
      const tmpData = this.$store.state.list.find(item => item.id === parseInt(itemId))
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
